.bg-img1{
    background-image:url('files/images/06.jpg'); background-position: center left; background-size: cover;
}

.bs-height{
    height: 55vh;
    color: white;

}

.carousel-radius{
    border-radius: 25px;
}

.carousel-img{
    background-color: red !important;
}